import { useEffect, useRef, useState } from "react";
import Link from "next/link";

import Nav from "react-bootstrap/Nav";

import Layout from "../../components/layout";
import { fetchEnrollmentPageProps, fetchRunningGames, fetchEnrollmentGamesForHome } from "../../lib/api/games";
import { getEnrollmentGamesInitialData } from "../../lib/games";
import ScheduleDateSelector from "../../components/home/scheduledateselector";
import Schedule from "../../components/home/schedule";
import { useRouter } from "next/router";
import { isMeet4USite } from "../../lib/coreutils";

function CollapseExpandSelector({ isExpanded, setIsExpanded }) {
    const setIsExpandedBool = (value) => {
        setIsExpanded(value === "true");
    };
    if (isMeet4USite()) {
        return null;
    }
    return (
        <div className="nav-pills--with-bg enrollment__expand-pills">
            <Nav variant="pills" activeKey={isExpanded} onSelect={setIsExpandedBool}>
                <Nav.Item>
                    <Nav.Link eventKey={"false"}>Кратко</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey={"true"}>Подробно</Nav.Link>
                </Nav.Item>
            </Nav>
        </div>
    );
}

export default function Enrollment({
    layoutProps,
    currentCity,
    running_games, // TODO: сюда (и на стартовую) всегда передается пустой массив. Надо удалить эту логику AZOB#11396
    enrollment_dates,
    enrollment_clubs,
    enrollment_places,
    enrollment_game_types,
    enrollment_game_levels,
    enrollment_host_levels,
    enrollment_games,
    enrollment_matrix,
    initialSelectedGameType,
    initialClub,
    initialPlace,
    initialGameLevel,
    initialSelectedHostLevel,
    initialSelectedDate,
}) {
    const [isExpanded, setIsExpanded] = useState(false);

    const [selectedEnrollmentDate, setSelectedEnrollmentDate] = useState(initialSelectedDate);
    const [selectedEnrollmentGameType, setSelectedEnrollmentGameType] = useState(initialSelectedGameType);
    const [selectedEnrollmentClub, setSelectedEnrollmentClub] = useState(initialClub);
    const [selectedEnrollmentPlace, setSelectedEnrollmentPlace] = useState(initialPlace);
    const [selectedEnrollmentGameLevel, setSelectedEnrollmentGameLevel] = useState(initialGameLevel);
    const [selectedEnrollmentHostLevel, setSelectedEnrollmentHostLevel] = useState(initialSelectedHostLevel);
    const [enrollmentGames, setEnrollmentGames] = useState(enrollment_games);
    const [runningGames, setRunningGames] = useState(running_games);
    const router = useRouter();

    const getFilterValues = () => {
        return {
            date: enrollment_dates.length > 0 ? enrollment_dates[selectedEnrollmentDate].date : "",
            club: enrollment_clubs.length > 0 ? enrollment_clubs[selectedEnrollmentClub].id : "",
            place: enrollment_places.length > 0 ? enrollment_places[selectedEnrollmentPlace].id : "",
            gameType: enrollment_game_types.length > 0 ? enrollment_game_types[selectedEnrollmentGameType].id : "",
            gameLevel: enrollment_game_levels.length > 0 ? enrollment_game_levels[selectedEnrollmentGameLevel].id : "",
            hostLevel: enrollment_host_levels.length > 0 ? enrollment_host_levels[selectedEnrollmentHostLevel].id : "",
        };
    };

    const refreshSchedule = async () => {
        const { date, place, gameType, gameLevel, hostLevel, club } = getFilterValues();
        setEnrollmentGames(
            await fetchEnrollmentGamesForHome(currentCity.slug, date, gameType, hostLevel, place, gameLevel, club)
        );
    };

    const refreshRunningGames = async () => {
        setRunningGames(await fetchRunningGames(currentCity.slug));
    };

    const isInitialMount = useRef(true);

    useEffect(() => {
        // Запускаем обновление расписания только в случае интерактивного выбора даты.
        // При первоначальной загрузке страницы расписание уже заполнено нормально.
        if (isInitialMount.current) {
            isInitialMount.current = false;
            return;
        }
        refreshSchedule();

        if (window && window.location && window.history) {
            const { date, place, club, gameType, gameLevel } = getFilterValues();
            const searchParams = new URLSearchParams();
            if (date != "" || !date.startsWith("1970-01")) {
                searchParams.set("date", date.toString());
            }
            if (club != "") {
                searchParams.set("club", club.toString());
            }
            if (place != "") {
                searchParams.set("place", place.toString());
            }
            if (gameType != "") {
                searchParams.set("game_type", gameType.toString());
            }
            if (gameLevel != "") {
                searchParams.set("game_level", gameLevel.toString());
            }
            if (searchParams.size > 0) {
                router.replace(`${window.location.pathname}?${searchParams.toString()}`, undefined, {
                    shallow: true,
                    scroll: false,
                });
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        selectedEnrollmentDate,
        selectedEnrollmentHostLevel,
        selectedEnrollmentGameType,
        selectedEnrollmentClub,
        selectedEnrollmentPlace,
        selectedEnrollmentGameLevel,
    ]);

    const enrollmentScheduleTitle =
        enrollment_dates.length > 0 ? enrollment_dates[selectedEnrollmentDate].title_date : "сегодня";

    const dateSelector = (
        <ScheduleDateSelector
            gameTypes={enrollment_game_types}
            dates={enrollment_dates}
            clubs={enrollment_clubs}
            places={enrollment_places}
            gameLevels={enrollment_game_levels}
            hostLevels={enrollment_host_levels}
            enrollmentMatrix={enrollment_matrix}
            selectedGameType={selectedEnrollmentGameType}
            selectedHostLevel={selectedEnrollmentHostLevel}
            selectedDate={selectedEnrollmentDate}
            selectedClub={selectedEnrollmentClub}
            selectedPlace={selectedEnrollmentPlace}
            selectedGameLevel={selectedEnrollmentGameLevel}
            city={currentCity}
            onGameTypeSelect={setSelectedEnrollmentGameType}
            onClubSelect={setSelectedEnrollmentClub}
            onPlaceSelect={setSelectedEnrollmentPlace}
            onGameLevelSelect={setSelectedEnrollmentGameLevel}
            onHostLevelSelect={setSelectedEnrollmentHostLevel}
            onDateSelect={setSelectedEnrollmentDate}
        />
    );

    return (
        <Layout pageClass="page-enrollment" menuPath="/enrollment/" {...layoutProps}>
            <div className="container-fluid">
                <div className="enrollment-title">
                    <h2>Расписание игр в {currentCity.name_prepositional}</h2>
                    <span className="mx-2 fs-3">/</span>
                    <Link href={`/${currentCity.slug}/archive/`}>
                        <a className="flex-title__link">Архив игр</a>
                    </Link>
                    <CollapseExpandSelector isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
                </div>
            </div>

            {runningGames.length > 0 && (
                <Schedule
                    title="Сейчас идут"
                    games={runningGames}
                    currentCity={currentCity}
                    showPlacesLeftInfo
                    showFormula
                    onGameChange={refreshRunningGames}
                    className="enrollment"
                />
            )}
            <Schedule
                title={`Игры ${enrollmentScheduleTitle}`}
                games={enrollmentGames}
                currentCity={currentCity}
                dateSelector={dateSelector}
                dateSelectorAboveTitle
                isExpandedEnrollment={isExpanded}
                isExpandableEnrollment
                showEmptyGameList
                showPlacesLeftInfo
                showFormula
                showLoginForAnonymous
                onGameChange={refreshSchedule}
                className="enrollment"
            />
        </Layout>
    );
}

export async function getServerSideProps({ req, query }) {
    const cookies = (req && req.cookies) || {};
    const props = await fetchEnrollmentPageProps(
        query.city,
        query.date,
        query.place,
        query.type || query.game_type,
        query.game_level,
        query.club,
        cookies
    );

    const {
        enrollment_game_types,
        enrollment_clubs,
        enrollment_places,
        enrollment_game_levels,
        enrollment_host_levels,
        initialSelectedGameType,
        initialClub,
        initialPlace,
        initialGameLevel,
        initialSelectedHostLevel,
        initialSelectedDate,
    } = getEnrollmentGamesInitialData(
        props,
        query.type || query.game_type,
        query.date,
        query.place,
        query.game_level,
        query.club
    );

    return {
        props: {
            ...props,
            enrollment_game_types,
            enrollment_clubs,
            enrollment_places,
            enrollment_game_levels,
            enrollment_host_levels,
            initialSelectedGameType,
            initialSelectedHostLevel,
            initialSelectedDate,
            initialClub,
            initialPlace,
            initialGameLevel,
            key: `${query.city}/${query.type || query.game_type || ""}`,
        },
    };
}
